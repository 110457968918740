<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Dokumen </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updatePengajuan">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="nama"
                  label="Nama Berkas"
                  placeholder="Input Nama Berkas"
                />
              </CCol>

              <CCol sm="12">
                <CTextarea
                  v-model="deskripsi"
                  label="Deskripsi"
                  placeholder="Input Deskripsi"
                />  
              </CCol>
              <!-- <CCol sm="6">             
                <div class="form-group">
                <label>Jenis Artikel</label>
                  <select class="form-control" v-model="rubrik">
                    <option value="" disabled selected>Pilih Jenis</option>
                    <option v-for="r in rubriks" :value="r.id_rubrik">{{r.deskripsi}} </option>
                  </select>
                </div>
              </CCol> -->

              <CCol sm="6">
                <div class="form-group">
                  <label>File </label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                    <input type="hidden" v-model="foto2">
                  </div>
                </div>  
              </CCol>

            </CRow>
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/pengajuan">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>      
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      nama : "",
      deskripsi : "",
      file : "",
      file_text : "",
      // rubrik : "",
      placeholder : "Pilih File",
      // rubriks : [],
      selected : [], // Must be an array reference!
      show: true,
      modalError:false,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showPengajuan();
  // this.loadData();
  },
  methods: {

    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.file_text = fileData.name;
      this.file = fileData;
      this.placeholder = fileData.name;
    },

    // loadData: function(event){
      
    //   let username =window.btoa(this.$route.params.id_user);
    //   console.log(username);

    //   axios.get(process.env.VUE_APP_BASE_URL+"rubriks")
    //     .then((response) => {
    //           this.rubriks=response.data;
    //     })
    // },

    updatePengajuan: function(event){
      
      const formData = new FormData()

      formData.append('id_pengajuan_penebangan', this.$route.params.id_pengajuan_penebangan)      
      formData.append('nama', this.nama)
      formData.append('deskripsi', this.deskripsi)
      formData.append('foto2', this.foto2)

      if(this.file_text){
        formData.append('file', this.file, this.file.name)
        formData.append('file_text', this.file.name)
      }

      axios.post(process.env.VUE_APP_BASE_URL+"pengajuanpenebangan/"+this.$route.params.id_pengajuan_penebangan, formData)
        .then((response) => {
            if(response.data.status==='success'){
              this.$router.push('/pengajuan');
            }
            else{
              this.modalError = true;
            }  
        })
    },
    
    showPengajuan: function(event){
      // Simple POST request with a JSON body using axios
      const pengajuan = { 
                          nama:this.nama,
                          deskripsi:this.deskripsi,
                          // file: this.file 
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"pengajuanpenebangan/"+this.$route.params.id_pengajuan_penebangan)
        .then((response) => {

              const json = response.data.data;
              this.nama=json.nama;
              this.deskripsi=json.deskripsi;
              this.placeholder=json.file;             
              // this.foto2=json.file;
        })
    }
  }
}
</script>